
import { dispatchSubmitRecoverPassword } from '@/store/main/actions';
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class RecoverPassword extends Vue {
  public loadingState: any = {
    submit: false,
  };
  public resetEmail: string = '';
  public formError: boolean = false;
  public resetError: string = '';
  public resetOK: boolean = false;

  public validateForm() {
    if (!this.resetEmail) {
      this.formError = true;
    } else {
      this.formError = false;
    }
    return !this.formError;
  }

  public async handleSubmit() {
    this.resetError = '';
    if (await this.validateForm()) {
      this.loadingState.submit = true;
      const response = await dispatchSubmitRecoverPassword(this.$store, {email: this.resetEmail});
      if (response.success) {
        this.resetOK = true;
      } else {
        this.resetError = response.error;
      }
      this.loadingState.submit = false;
    }
  }

}
